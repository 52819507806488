import React from "react"
import { graphql, Link } from "gatsby"
import _ from 'lodash'
import PageTransition from 'gatsby-plugin-page-transitions';
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import "./../components/DynamicPage.css"




function GeneralTemplate(props) {
    let data = props.data.wpgraphql;
    
    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://pravihotel.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://pravihotel.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <PageTransition>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                template={ data.template.contactInformationTemplate }

            >
                <div style={{minHeight: "65vh"}}>
                    <div>
                        {data.page.featuredImage && (
                            <BackgroundImage
                                Tag="section"
                                className="background-dm-container"
                                fluid={data.page.featuredImage.imageFile.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                            >
                                <div>
                                    <h1 className="text-white">
                                        {data.page.title}
                                    </h1>
                                </div>
                            </BackgroundImage>
                        )}
                    </div>
                    <div className="container m-auto py-16 dynamic-content" dangerouslySetInnerHTML={{ __html: data.page.content }}></div>
                </div>
            </Layout>
        </PageTransition>
    )
}

export default GeneralTemplate;

export const pageQuery = graphql`
    query GET_DYNAMIC_PAGE($id: ID! , $themeTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                content
                title
                featuredImage{
                    altText
                    sourceUrl
                    imageFile{
                        childImageSharp{
                            fluid(maxWidth: 2080, quality:80){
                                base64
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
            }
            template(id: $themeTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    informationTitle
                    telephoneNumber
                    supportEmail
                    address {
                        addressGoogleLink
                        addressLabel
                    }
                }
            }
        }
    }
`
